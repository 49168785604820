<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonCartPageInfo.Label_Page_Title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search
            class="top-nav-search"
            :showDescriptionText="showDescriptionText"
            :showMerchTotal="showMerchTotal"
            :backLabel="gideonCartPageInfo.Label_Back_Description"
            :i18n="translations.components"
          >
          </top-nav-search>
          <camp-selector
            @camp_changed="page_load($event)"
            :onlyUSA="onlyUSA"
            :tooltip="translations.tcCurrentHome"
            class="mt-3 mb-1"
            :i18n="translations.components"
          />
        </b-col>
      </b-row>
      <b-row class="tile-row" v-if="demograph.camp_key === userCampKey">
        <!-- Merchandise -->
        <b-col sm="12" xs="12" class="tile-col-details mb-5" v-if="isToggled">
          <data-table
            :title="this.gideonCartPageInfo.Label_MerchandiseCardTitle"
            :fields="dataTableFields"
            :items="gideonCartPageInfo.MerchandiseItems"
            :toggle="showToggle"
            :hidePagination="hidePagination"
            :addItems="addMerchandiseItems"
            :noRecordsMessage="this.gideonCartPageInfo.Label_NoRecordsFound"
            :i18n="translations.components"
            @itemQuantityChanged="changedItemQuantity"
            @deleteItem="deleteItem"
            @clearCart="clearCart('merchandise')"
          >
          </data-table>
          <!-- / Data Tables -->
          <div class="cart-footer">
            <b-button
              @click="handleCheckout('merchandise')"
              variant="primary"
              class="btnCheckout mr-5"
              :disabled="this.gideonCartPageInfo.MerchandiseItems.length === 0"
            >
              {{ this.gideonCartPageInfo.Button_Checkout }}
            </b-button>
            <div id="Subtotal" class="subtotal">
              <h5>
                {{ this.gideonCartPageInfo.Label_Subtotal }}
                {{ merchandiseCartTotal }}
              </h5>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="tile-row" v-if="user_accessible">
        <!-- Scripture Cart -->
        <b-col sm="12" xs="12" class="tile-col-details mb-5" v-if="isToggled2">
          <data-table
            :title="this.gideonCartPageInfo.Label_ScriptureCardTitle"
            :fields="dataTableFields"
            :items="gideonCartPageInfo.ScriptureItems"
            :toggle="showToggle"
            :hidePagination="hidePagination"
            :addItems="addScriptureItems"
            :noRecordsMessage="this.gideonCartPageInfo.Label_NoRecordsFound"
            :i18n="translations.components"
            @itemQuantityChanged="changedItemQuantity"
            @deleteItem="deleteItem"
            @clearCart="clearCart('scripture')"
          ></data-table>
          <!-- / Data Tables -->
          <div class="cart-footer">
            <b-button
              @click="handleCheckout('scripture')"
              variant="primary"
              class="btnCheckout mr-5"
              :disabled="
                this.gideonCartPageInfo.ScriptureItems.length === 0 || !this.gideonCartPageInfo.isAbleToOrderScripture
              "
            >
              {{ this.gideonCartPageInfo.Button_Checkout }}
            </b-button>

            <div id="Subtotal" class="subtotal text-right">
              <h5>
                {{ this.gideonCartPageInfo.Label_CampFundBalance }}
                {{
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    this.gideonCartPageInfo.CampScriptureFundBalance
                  )
                }}
                &mdash;
                {{ this.gideonCartPageInfo.Label_Subtotal }}
                {{ scriptureCartTotal }}
              </h5>
              <h5>
                {{ this.gideonCartPageInfo.Label_ServiceTestamentTotal }}
                {{
                  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    this.gideonCartPageInfo.ServiceTestamentTotal
                  )
                }}
              </h5>
              <h6
                class="text-danger"
                v-html="gideonCartPageInfo.Label_ExceededCSFB"
                v-if="!this.gideonCartPageInfo.isAbleToOrderScripture"
              ></h6>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableGideonStore.vue'
import { securityMixin } from '@/mixins/securityMixin'
import TopNavSearch from './TopNavSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-cart',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        store_scripture_button: 'dd37ad10-32f0-4607-833f-8b6c41d22bc0',
      },
      user_accessible: false,
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Cart',
        cmp_key: '',
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      showDescriptionText: false,
      showMerchTotal: false,
      isToggled: true,
      isToggled2: true,
      showToggle: true,
      hidePagination: true,
      tooltip: 'Testing',
      dataTableFields: [],
      addMerchandiseItems: {
        text: 'Clear Cart',
        display: true,
        action: 'clearCart',
      },
      addScriptureItems: {
        text: 'Clear Cart',
        display: true,
        action: 'clearCart',
      },
      onlyUSA: true,
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    topNavSearch: TopNavSearch,
  },
  methods: {
    ...mapActions({
      clearStoreCart: 'gideonStore/clearStoreCart',
      getControlPermissions: 'user/getControlPermissions',
      loadStoreCartPage: 'gideonStore/loadStoreCartPage',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setStoreCartCheckoutType: 'gideonStore/setStoreCartCheckoutType',
      setStoreShippingIndKey: 'gideonStore/setStoreShippingIndKey',
      updateStoreCart: 'gideonStore/updateStoreCart',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          this.setLoadingStatus(true)
          this.storeNavPagePayload.lang = this.prefCulture
          this.storeLPPayload.lang = this.prefCulture
          this.storeLPPayload.cmp_key = this.userCampKey
          await Promise.all([
            await this.checkPermissions(),
            await this.loadStoreCartPage(this.storeLPPayload),
            await this.loadStoreNavigationPage(this.storeNavPagePayload),
          ]).then(() => {
            this.dataTableFields = [
              { key: 'vprd_product_name', label: this.gideonCartPageInfo.Label_Product, sortable: true },
              { key: 'vprd_prd_code', label: this.gideonCartPageInfo.Label_Item, sortable: true },
              { key: 'shc_quantity', label: this.gideonCartPageInfo.Label_Quantity, sortable: true },
              {
                key: 'vprd_prd_display_price_cp',
                label: this.gideonCartPageInfo.Label_Price,
                sortable: true,
                formatter: (value, key, item) => {
                  let price = '$' + Number(value).toFixed(2)
                  return price
                },
              },
              {
                key: 'shc_prd_key',
                label: this.gideonCartPageInfo.Label_Delete,
                sortable: false,
                thStyle: {
                  display: 'none',
                },
                tdStyle: {
                  'text-align': 'center',
                },
              },
            ]
            this.addMerchandiseItems.display = this.gideonCartPageInfo.MerchandiseItems.length > 0 ? true : false
            this.addScriptureItems.display = this.gideonCartPageInfo.ScriptureItems.length > 0 ? true : false
            this.addMerchandiseItems.text = this.gideonCartPageInfo.Button_ClearCart
            this.addScriptureItems.text = this.gideonCartPageInfo.Button_ClearCart
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async checkPermissions() {
      await this.getControlPermissions(this.secured_controls.store_scripture_button).then(() => {
        this.user_accessible = this.determineAccessibility(this.secured_controls.store_scripture_button)
      })
    },
    async clearCart(product_type) {
      let response = false
      await Promise.all([
        //add in the call to add to cart
        (response = await this.clearStoreCart(product_type)),
        this.setLoadingStatus(true),
        await this.loadStoreCartPage(this.storeLPPayload),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorClearingCart || 'There was an error clearing the cart.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
      if (product_type === 'merchandise') {
        this.addMerchandiseItems.display = false
      } else {
        this.addScriptureItems.display = false
      }
    },
    async deleteItem(data) {
      await this.updateStore({
        prd_key: data.shc_prd_key,
        quantity: 0,
      })
    },
    async changedItemQuantity(data) {
      await this.updateStore({
        prd_key: data.shc_prd_key,
        quantity: data.shc_quantity,
      })
    },
    async updateStore(payload) {
      let response = false
      await Promise.all([(response = await this.updateStoreCart(payload)), await this.page_load()])
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorDeletingItem || 'There was an error deleting the Item.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
    },
    async handleCheckout(cartType) {
      this.setStoreCartCheckoutType(cartType)
      this.setSelectedIndividualKey('')
      this.setStoreShippingIndKey('')
      this.$router.push({ name: 'store-cart-checkout' })
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      gideonCartPageInfo: 'gideonStore/gideonCartPageInfo',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userLogin: 'user/userLogin',
    }),
    merchandiseCartTotal() {
      let cartTotal = 0
      if (this.gideonCartPageInfo.MerchandiseItems.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        cartTotal = this.gideonCartPageInfo.MerchandiseItems.map((x) => x.sum_line_amount).reduce(reducer)
      }
      return '$' + cartTotal.toFixed(2)
    },
    scriptureCartTotal() {
      let cartTotal = 0
      if (this.gideonCartPageInfo.ScriptureItems.length > 0) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        cartTotal = this.gideonCartPageInfo.ScriptureItems.map((x) => x.sum_line_amount).reduce(reducer)
      }
      return '$' + cartTotal.toFixed(2)
    },
  },
  async created() {
    try {
      await Promise.all([
        this.page_load(),
        this.setLoadingStatus(true),
        await this.getViewTranslations(),
        await this.getComponentTranslations('camp-select', 'data-table-store', 'store-topnav-search'),
      ]).then((results) => {
        const componentTranslations = results[3]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}
.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}
.switch-buttons {
  margin: 0 auto;
  text-align: center;
  .merchscriptBtn {
    background-color: #747474;
  }
  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  // padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;
  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    .category-ddl {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;
      .btn-go {
        padding: 8px;
      }
    }
  }
  .tile-col-details {
    padding: 0;
    .cart-footer {
      display: flex;
      flex-direction: row;
      align-content: stretch;
      justify-content: space-between;
      padding: 12px 47px;
    }
  }
  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    .tile-col-card {
    }
  }
}

.tiles {
  margin-bottom: 80px;
}

.arrow-down-icon {
  color: #000000;
  cursor: pointer;
}
.arrowup {
  cursor: pointer;
  img {
    transform: rotate(180deg);
  }
}

.btnCheckout {
  max-height: 50px;
}
</style>
